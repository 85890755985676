.information-page a:not(.btn) {
  color: #f26122;
  text-decoration: underline;
}
.information-page a,
.information-page a:hover {
  color: #000000;
}

.information-page table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: transparent;
}
.information-page table.sizeguide {
  overflow-x: auto;
  white-space: nowrap;
}
.information-page tbody {
  width: 100%;
}
.information-page tr {
  width: 100%;
}
.information-page tr td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  border: 1px solid #ddd;
  word-break: break-word;
}
.information-page tbody tr:first-child td {
  border: none;
  padding-top: 20px;
}
.information-page tbody tr.sg_colHead td {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  padding: 8px;
  line-height: 1.42857143;
  text-align: left;
  border: 1px solid #ddd;
  background-color: #eee;
  border-bottom-width: 2px;
  font-weight: 700;
}
.information-page tbody tr.sg_colHead td p {
  margin: 0;
  display: inline;
}
.information-page ul {
  padding-left: 0;
  margin: 0;
  list-style: none;
}
.information-page p {
  margin-top: 0;
  margin-bottom: 24px;
}
