html {
  -webkit-text-size-adjust: 100%;
}
html,
body,
body > #__next {
  width: 100%;
  height: 100%;
}
a {
  -webkit-tap-highlight-color: transparent;
}
textarea,
input {
  @supports (-webkit-overflow-scrolling: touch) {
    font-size: 16px !important;
  }
}
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
body {
  margin: 0;
}

.luigi-ac-grid .luigi-ac-name {
  text-decoration: none;
}

.luigi-ac-products.luigi-ac-main .luigi-ac-item.luigi-ac-other {
  width: 33%;
}

#_amio_webchat_container {
  right: 0px !important;
}

.luigi-ac-type-category .luigi-ac-image,
.luigi-ac-type-brand .luigi-ac-image {
  display: none !important;
}

@media (max-width: 767px) {
  .luigi-ac-products.luigi-ac-main .luigi-ac-item.luigi-ac-other {
    width: 100% !important;
  }

  .luigi-ac.luigi-ac-grid.luigi-ac-autocomplete.luigi-ac-open {
    width: 100% !important;
    max-width: 100vw !important;
    left: 0 !important;
    padding: 7px 0;
  }

  #daktela-web .dw-button {
    left: 10px !important;
  }
}

@media (min-width: 768px) {
  #_amio_webchat_container {
    right: 10px !important;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .luigi-ac-item.luigi-ac-other.luigi-ac-type-brand,
  .luigi-ac-item.luigi-ac-other.luigi-ac-type-category,
  .luigi-ac.luigi-ac-grid.luigi-ac-autocomplete.luigi-ac-open {
    width: 100% !important;
    max-width: 100vw !important;
    left: 0 !important;
    padding: 7px 0;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0 !important;
}

/* checkout-frames styles */
.card-number-frame {
  padding-left: 40px;
}

.expiry-date-frame {
  padding-left: 40px;
}

.cvv-frame {
  padding-left: 40px;
}

.frame--activated {
  opacity: 1;
  border: solid 1px #808080;
}

.frame--activated.frame--focus {
  border: solid 1px #808080;
}

.frame--activated.frame--invalid {
  border: solid 1px #d96830;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

/* lightbox styles */
.light-style-wrapper.ril__outer {
  background-color: rgba(255, 255, 255, 1);
}
.light-style-wrapper .ril__loadingCirclePoint::before {
  background-color: #000;
}
.light-style-wrapper .ril__caption,
.light-style-wrapper .ril__toolbar {
  background-color: transparent;
}
.light-style-wrapper .ril__closeButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiMwMDAiLz48L3N2Zz4K')
    no-repeat center;
}
.light-style-wrapper .ril__zoomInButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+Cg==')
    no-repeat center;
}
.light-style-wrapper .ril__zoomOutButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4K')
    no-repeat center;
}
.light-style-wrapper .ril__navButtonPrev {
  left: 0;
  background: rgba(255, 255, 255, 0.7)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iIzAwMCIvPjwvc3ZnPgo')
    no-repeat center;
}
.light-style-wrapper .ril__navButtonNext {
  right: 0;
  background: rgba(255, 255, 255, 0.7)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjMDAwIi8+PC9zdmc+Cg==')
    no-repeat center;
}

.luigi-ac-button {
  background-color: var(--color-accent) !important;
}
.luigi-ac-price-discount {
  color: var(--color-accent) !important;
}

.swiper-pagination-bullet {
  background: var(--color-onBackground) !important;
  opacity: 0.2 !important;
}

.swiper-pagination-bullet-active {
  background: var(--color-onBackground) !important;
  opacity: 1 !important;
}
