* {
  font-family: Factcool, sans-serif !important;
}

@font-face {
  font-family: 'fcicon';
  src: url('/fonts/fcicon.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^='fcicon-'],
[class*=' fcicon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fcicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fcicon-clock:before {
  content: '\e91f';
}
.fcicon-arrow-up:before {
  content: '\e91b';
}
.fcicon-arrow-down:before {
  content: '\e91c';
}
.fcicon-arrow-left:before {
  content: '\e91d';
}
.fcicon-arrow-right:before {
  content: '\e91e';
}
.fcicon-heart-filled:before {
  content: '\e911';
}
.fcicon-cms-shield:before {
  content: '\e91a';
}
.fcicon-cms-happy:before {
  content: '\e918';
}
.fcicon-cms-tshirt:before {
  content: '\e919';
}
.fcicon-question-mark:before {
  content: '\e917';
}
.fcicon-return:before {
  content: '\e910';
}
.fcicon-close:before {
  content: '\e90f';
}
.fcicon-heart:before {
  content: '\e90e';
}
.fcicon-order-waiting:before {
  content: '\e912';
}
.fcicon-order-delivering:before {
  content: '\e913';
}
.fcicon-order-success:before {
  content: '\e914';
}
.fcicon-close-order:before {
  content: '\e915';
}
.fcicon-alert:before {
  content: '\e916';
}
.fcicon-carret-down:before {
  content: '\e900';
}
.fcicon-facebook:before {
  content: '\e901';
}
.fcicon-home:before {
  content: '\e902';
}
.fcicon-refresh:before {
  content: '\e903';
}
.fcicon-instagram:before {
  content: '\e904';
}
.fcicon-envelope:before {
  content: '\e905';
}
.fcicon-phone:before {
  content: '\e906';
}
.fcicon-back:before {
  content: '\e907';
}
.fcicon-savings:before {
  content: '\e908';
}
.fcicon-search:before {
  content: '\e909';
}
.fcicon-shopping-bag:before {
  content: '\e90a';
}
.fcicon-shopping-cart:before {
  content: '\e90b';
}
.fcicon-delivery:before {
  content: '\e90c';
}
.fcicon-user:before {
  content: '\e90d';
}

.fcicon-linkedin:before {
  content: '\e822';
}

.fcicon-twitter:before {
  content: '\e821';
}

.fcicon-pinterest:before {
  content: '\e820';
}
.fcicon-instagram2:before {
  content: '\e823';
}

.fcicon-check-mark-white:before {
  content: '\e824';
}
